import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native'


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';


import { Image } from 'expo-image';
import Button from '../../components/Forms/Button';

import { useForm } from 'react-hook-form';


export default function InstallWebApp({ code, onFinish }) {
  const [submitting, setSubmitting] = useState();

  async function installWA(){
    if (window?.deferredPrompt){
      window?.deferredPrompt.prompt();

      // Find out whether the user confirmed the installation or not
      const { outcome } = await window.deferredPrompt.userChoice;
      // The deferredPrompt can only be used once.
      window.deferredPrompt = null;
      // Act on the user's choice
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt.');
      } else if (outcome === 'dismissed') {
        console.log('User dismissed the install prompt');
      }
    } else {
      
    }
    
    onFinish?.()
  }

  return (
    <View>
      <Text style={tw`text-lg mb-10`}>Para terminar puedes instalar esta app para tenerla a la mano</Text>

      <Button
        disabled={submitting}
        label='Instalar'
        style={{ container: tw`mt-10` }}
        onPress={installWA}
      />

      <Text style={tw`text-lg self-center py-2 px-3 mt-4`} onPress={onFinish}>Ahora no</Text>
    </View>
  )
}