import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';
import { Image } from 'expo-image';
import { useSelector } from 'react-redux';




export default function FeedbackScreen({ route, navigation }) {
  const { selectedBusiness } = useSelector(state => state.status)

  const { isLoading, error, data: orders } = useQuery({
    queryKey: ['businessReviews'],
    queryFn: getReviews,
  })


  async function getReviews({ queryKey }) {
    const params = {}

    return api.get(`/business/${selectedBusiness.uuid}/reviews`, { params })
      .then(response => response.data)
  }



  // if (isLoading) return <Text style={tw`text-lg text-gray-600 mt-10 text-center`}>Cargando...</Text>

  // if (error) return <Text>Ocurrió un error: {error.message}</Text>


  return (
    <ScrollView style={tw``} contentContainerStyle={tw`px-7 my-8`}>
      {/* <Text style={tw`mt-4 mb-4 text-xl font-bold text-center`}>Reseñas</Text> */}
      
      {
        orders?.map(order => {
          const orderDate = new Date(order.purchase.reviewed_at)
          const dateStr = `${orderDate.getDate()}/${orderDate.getMonth()}/${orderDate.getFullYear()}` //  ${orderDate.getHours()}:${orderDate.getMinutes()}

          return (
            <View
              key={order.uuid}
              style={tw`py-3 bg-white px-3 mb-3 rounded-lg`}
              // onPress={() => navigation.navigate("Customers.Detail", { customerId: order.uuid })}
            >
              <View style={tw`row justify-between border-b border-gray-200 pb-2`}>
                <View style={tw`row`}>
                  <Text style={tw`font-bold mr-3`}>#{order.ticket_id}</Text>
                  <Text style={tw`text-gray-500`}>{dateStr}</Text>
                </View>
                    
                {
                  order.type == "delivery" ?
                    <Image source={require('../../assets/images/icons/delivery.svg')} style={tw`h-4 w-4 mr-2`} contentFit="contain" />
                    :
                    <Image source={require('../../assets/images/icons/en_lugar.svg')} style={tw`h-4 w-4 mr-2`} contentFit="contain" />
                }
              </View>

              {/* <Text>{order.delivery_platform}</Text> */}

              {/* Calificaciones */}
              <View style={tw`row justify-between py-2  border-b border-gray-200`}>
                {/* Comida */}
                <View style={tw`flex-1 items-center`}>
                  <View style={tw`row`}>
                    {
                      Array(order.purchase.product_rating).fill("").map(e =>
                        <Image source={require('../../assets/images/icons/estrella.svg')} style={tw`h-4 w-4`} contentFit="contain" />
                      )
                    }
                  </View>

                  <Text style={tw`text-gray-500 mt-1`}>Comida</Text>
                </View>

                {/* Servicio */}
                <View style={tw`flex-1 items-center`}>
                  <View style={tw`row`}>
                    {
                      Array(order.purchase.service_rating).fill("").map(e =>
                        <Image source={require('../../assets/images/icons/estrella.svg')} style={tw`h-4 w-4`} contentFit="contain" />
                      )
                    }
                  </View>

                  <Text style={tw`text-gray-500 mt-1`}>Servicio</Text>
                </View>
              </View>

              {/* Comentario */}
              <View style={tw`row mt-3 items-start`}>
                <Image source={require('../../assets/images/icons/rev.svg')} style={tw`h-4 w-4 mr-3`} contentFit="contain" />

                <View style={tw`flex-1`}>
                  <Text>{order.purchase.review_comments}</Text>
                  <Text style={tw`text-gray-400`}>{order.customer[0].name}</Text>
                </View>
              </View>
            </View>
          )
        })
      }

    </ScrollView>
  );
}
