import api from "../../utils/api";

const types = {
  STATUS_UPDATED: 'STATUS_UPDATED',
  SET_SELECTED_BUSINESS: 'SET_SELECTED_BUSINESS',
};



export function updateStatus(){
  return (dispatch, getState) => {
    api.get(`/status`)
      .then(({ data }) => {
        const state = getState()

        dispatch(statusUpdated(data))

        if (!state.status.selectedBusiness && data.business?.length > 0){
          dispatch(setSelectedBusiness(data.business[0]))
        }
      })
      .catch(error => { })
  }
}

export function statusUpdated(status){
  return { type: types.STATUS_UPDATED, status }
}

export function setSelectedBusiness(business){
  return { type: types.SET_SELECTED_BUSINESS, business }
}


const initialState = {
  selectedBusiness: null,
};



export const reducer = (state = initialState, action) => {
  const {type, user} = action;

  switch (type) {
    case types.STATUS_UPDATED:
      return Object.assign({}, state, {
        ...action.status,
      });

    case types.SET_SELECTED_BUSINESS:
      return Object.assign({}, state, {
        selectedBusiness: action.business,
      });

    default:
      return state;
  }
};
  