
import React, {useState} from 'react';
import { Pressable, View, Text } from "react-native";

import { useController } from "react-hook-form";


// Wrapper de esta librería
// https://github.com/lawnstarter/react-native-picker-select
import RNPickerSelect from 'react-native-picker-select';
import tw from '../../styles/tailwind';



export default function Select(props){
  const [selectedOption, setSelectedOption] = useState(null);

  if (props.control)
  var { field, fieldState: { invalid, isTouched, isDirty, error } } = useController({
    control: props.control,
    defaultValue: props.value,
    name: props.name,
    rules: props.rules,
  })


  const inputStyles = props.style ? props.style.input : {}
  const errorStyles = invalid ? { borderColor: `red`} : {}


  function onValueChange(selectedValue, index){
    if(selectedValue == undefined) return
    
    const item = props.items.find(item => item.value == selectedValue)

    props.onChange && props.onChange(item.value)

    // form
    field && field.onChange(item.value)
  }

  const isInline = !!(props.inline && props.label)

  return <View style={[{}, props.style?.container]}>
    <View style={[{flexDirection: 'row', alignItems: 'center'}, props.style?.wrapper]}>
      {
        isInline && <Text style={[{ fontWeight: '600', color: "#64707d"}, props.style?.label]}>{props.label}</Text>
      }

      <View style={{flex: 1}}>
        <RNPickerSelect
          placeholder={props.placeholder}
          onValueChange={onValueChange}
          items={props.items}
          value={field.value}

          // Estilos
          useNativeAndroidPickerStyle={false}
          style={{
            viewContainer: {
              marginLeft: isInline ? 30 : 0,
            },
            
            inputWeb: [
              // commonStyle.select,
              { 
                marginBottom: 0, 
                appearance: 'none', 
                color: !!selectedOption ? null : tw.color('gray-700'), 
                opacity: props.disabled ? 0.5 : 1,
                paddingVertical: 16,
                paddingHorizontal: 16,
                borderRadius: 8,
                fontSize: 16,
              },
              props.style?.input,
              errorStyles,
              
            ],
            inputAndroidContainer: {
              marginLeft: isInline ? 30 : 0,
            },
            inputAndroid: { marginBottom: 0, ...props.style?.input, ...errorStyles, opacity: props.disabled ? 0.5 : 1, },
            placeholder: { color: `#aaa` },
            iconContainer: {
              top: "50%",
              right: 15,
              marginTop: -4
            },
          }}

          disabled={props.disabled}

          // Icono
          Icon={() => {
            return (
              <View
                style={{
                  backgroundColor: 'transparent',
                  borderTopWidth: 8,
                  borderTopColor: 'black',
                  borderRightWidth: 6,
                  borderRightColor: 'transparent',
                  borderLeftWidth: 6,
                  borderLeftColor: 'transparent',
                  width: 0,
                  height: 0,
                  borderRadius: 2,
                }}
              />
            );
          }}
        />
      </View>

    </View>

    {
      !!error?.message && <Text style={{ marginTop: 10, color: tw.color('red-400') }}>{error.message}</Text>
    }
  </View>
}