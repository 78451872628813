import React from "react";
import { Button, StyleSheet, Text, TextInput, View } from "react-native";
import { StatusBar } from "expo-status-bar";
import { useSelector, useDispatch } from 'react-redux';
import {actions} from "../../store/Reducers/auth";
import Input from "../../components/Forms/Input";
import { useForm } from "react-hook-form";
import api from "../../utils/api";


export interface IAppProps {}

export default function LoginScreen(props: IAppProps) {
  const dispatch = useDispatch()
  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, watch } = useForm();

  function submitForm(data){
    console.log(data);
    
    api.post(`/auth/login`, data)
    .then(({data}) => {
      dispatch(actions.login({}, data.token))
    })
    .catch(({response}) => {

    })
    // dispatch(actions.login({id: 123, name: "paco"}, "new token"))
  }

  return (
    
      <View style={styles.container} >
        <StatusBar style="auto" />

        <Input
          name='username'
          control={control}
          placeholder="Correo Electrónico"
          rules={{ required: true }}
        />

        <Input
          name='password'
          control={control}
          placeholder="Contraseña"
          rules={{ required: true }}
          secureTextEntry
        />
        {/* <TextInput style={styles.input} placeholder="Correo Electrónico"/> */}

    
        <Button 
          title={"Iniciar sesión"}
          onPress={handleSubmit(submitForm)}
        />
      </View>
  );
}


/*function Component(){}
const Component = () => {}
const Component = function(){}*/


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    paddingVertical: 15,
    paddingHorizontal: 45,
    marginBottom: 25,
    borderBottomColor: "#888",
    borderBottomWidth: 1,
  },
});
