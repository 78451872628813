import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';
import Input from '../../components/Forms/Input';
import { useForm } from 'react-hook-form';
import { Ionicons } from '@expo/vector-icons';
import Select from '../../components/Forms/Select';
import Button from '../../components/Forms/Button';
import { Image } from 'expo-image';
import { useSelector } from 'react-redux';




export default function NewCodeScreen({ route, navigation }) {
  const { selectedBusiness } = useSelector(state => state.status)

  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, watch } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [createdCode, setCreatedCode] = useState();
  const [addDiscount, setAddDiscount] = useState(false);
  const type = watch('type')

  function registerUser(data) {
    setSubmitting(true)

    const _data = {
      ...data,
    }

    api.post(`/business/${selectedBusiness.uuid}/codes`, _data)
      .then(({ data }) => {
        console.log("codes success", data);
        setCreatedCode(data)
      })
      .catch(({ response }) => {
        setSubmitting(false)
        console.error("codes error", response.data);
      })
  }
  return (
    <ScrollView style={tw``} contentContainerStyle={tw`px-7 my-2`}>
      {/* <Text style={tw`mt-4 mb-4 text-xl font-bold text-center`}>Nuevo código</Text> */}

   
     {
        ! createdCode &&
        <View>

          <View style={tw`mb-6`}>
            {/* <Text style={tw`text-lg`}>Canal del pedido</Text> */}

            <View style={tw`row mt-3`}>
              <TouchableOpacity
                onPress={() => setValue('type', 'delivery')}
                style={tw.style(`flex-1 rounded-l-xl bg-white items-center justify-center py-4`, type == "delivery" && `bg-black`)}
              >
                <Ionicons name='bicycle-outline' size={22} color={type == "delivery" ? "white" : "black"} />
                <Text style={tw.style(`text-lg mt-3`, type == "delivery" && `text-white`)}>Delivery</Text>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => setValue('type', 'in_place')}
                style={tw.style(`flex-1 rounded-r-xl border-l border-gray-400 bg-white items-center justify-center py-4`, type == "in_place" && `bg-black`)}
              >
                <Ionicons name='fast-food-outline' size={22} color={type == "in_place" ? "white" : "black"} />
                <Text style={tw.style(`text-lg mt-3`, type == "in_place" && `text-white`)}>Presencial</Text>
              </TouchableOpacity>
            </View>
          </View>

          {
            type == 'delivery' &&
            <Select
              name="delivery_platform"
              control={control}
              items={[
                { label: "Uber Eats", value: "uber_eats" },
                { label: "Didi Food", value: "didi_food" },
                { label: "Rappi", value: "rappi" },
                { label: "Urbo", value: "urbo" },
              ]}
              placeholder={{ label: "Plataforma de delivery", value: null }}
              style={{ wrapper: tw`mb-5`, input: tw`border-0` }}
            />
          }

          <Input
            name={'ticket_id'}
            control={control}
            placeholder={"# Ticket"}
            rules={{ required: true }}
            style={{ input: `text-lg` }}
          />

          <Input
            name={'amount'}
            control={control}
            placeholder={"Monto del ticket"}
            rules={{ required: true, pattern: {value: /\d+/, message: "El monto tiene que ser numérico"} }}
            style={{ input: `text-lg` }}
            keyboardType={"decimal-pad"}
          />

          {
            addDiscount ?
              <Input
                name={'discount_description'}
                control={control}
                placeholder={"Descripción del descuento"}
                // rules={{ required: true }}
                style={{ input: `text-lg` }}
              />
            :
              <Text style={tw`py-2 mb-8 mt-3 text-center font-500`} onPress={() => setAddDiscount(true)}>Agregar un descuento</Text>
          }


          <Button
            label='Crear Código'
            onPress={handleSubmit(registerUser)}
          />
        </View>
     }

     {
      createdCode &&
        <View style={tw``}>
          <Text style={tw`text-center font-600 text-xl mt-3`}>Código creado</Text>

          <Image source={createdCode.qr_url} style={tw`w-70 h-70 my-10 self-center`} />
        
          <Button
            label='Imprimir'
            onPress={() => {}}
          />
        </View>
     }

    </ScrollView>
  );
}
