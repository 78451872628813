import React, {useState, useEffect} from 'react';
import {TextInput,  View} from 'react-native'

import tw from '../../styles/tailwind';

import Text from './../Text';

import {Control, useController} from "react-hook-form";

interface InputProps{
  control: Control,
  name: string,
  style?: object,
  placeholder?: string,
  numberOfLines?: number,
  multiline?: boolean,
  uncontrolled?: boolean,
  rules?: object,
}

export default function Input(props: InputProps) {
  if (!props.uncontrolled)
    var {field, fieldState: {invalid, isTouched, isDirty, error}} = useController({
      control: props.control,
      defaultValue: '',
      name: props.name,
      rules: props.rules,
    })

  return (
    <>
      <View style={[tw`mb-5`, props.style?.container]}>
        <View style={[
          tw`bg-white w-full rounded-xl`, 
          // {borderColor: theme.COMPONENTS_BORDER_COLOR},
          invalid ? {borderColor: '#f44', borderWidth: 1} : {}, props.style?.subcontainer]}>
          
          {
            props.label && 
            <Text style={[tw.style(`absolute bg-primary ml-3 px-2`, { marginTop: -22, fontWeight: 'bold'}), props.style?.label]}>{props.label}</Text>
          }

          <TextInput
            value={!props.uncontrolled ? field.value : props.value}
            onChangeText={!props.uncontrolled ? field.onChange : null}
            onBlur={!props.uncontrolled ? field.onBlur : props.onBlur}
            ref={!props.uncontrolled ? field.ref : null}
            placeholderTextColor="#aaa"
            {...props}
            style={tw.style(`py-4 px-4 font-bold rounded-lg`, props.style?.input)}
          />
        </View>

        { !!error?.message && <Text style={tw.style(`mt-2`,)}>{error.message}</Text> }
      </View>
    </>
  )
}