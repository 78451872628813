/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import React, { useEffect, useState } from "react";
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme, useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ColorSchemeName, Pressable } from 'react-native';

import tw from "../styles/tailwind";



import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './LinkingConfiguration';

// 
import { useSelector, useDispatch } from "react-redux";
import { updateStatus } from "../store/Reducers/status";


/**
 * Import de las pantallas
 */
import LoginScreen from '../screens/Auth/LoginScreen'

import ExamplesScreen from '../screens/Examples/ExamplesScreen'
import SellDetailScreen from '../screens/Examples/DetailScreen'
import ExamplesStylesScreen from '../screens/Examples/StylesScreen'
import FormExampleScreen from "../screens/Examples/FormScreen";
import ReactQueryScreen from "../screens/Examples/ReactQueryScreen";


import NotFoundScreen from '../screens/NotFoundScreen';


// Pantallas que vienen con Expo de TabNavigations
import ModalScreen from '../screens/Examples/TabNavigation/ModalScreen';
import TabOneScreen from '../screens/Examples/TabNavigation/TabOneScreen';
import TabTwoScreen from '../screens/Examples/TabNavigation/TabTwoScreen';


const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    // primary: 'rgb(255, 45, 85)',
    // background: theme.BACKGROUND_COLOR,
    border: "transparent",
  },
};



export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      //
      // si se quiere usar tema oscuro hay que descomentar esto
      //
      // theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
      >
      {/*
        Este se puede intercambiar a el RootTabNavigator que 
        trae la app demo de Expo
      */}
      <RootNavigator />
      {/* <RootTabNavigator /> */}
    </NavigationContainer>
  );
}



/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();



function RootNavigator() {
  const auth = useSelector((state) => state.auth);
  const status = useSelector((state) => state.status);
  const dispatch = useDispatch()

  // Despachamos la accion para actualizar el status de la app
  useEffect(() => {
    dispatch(updateStatus())
  }, [auth.isLogged]);

  return (
    <Stack.Navigator
      screenOptions={{
        // Ejemplo de customizacion del header
        headerStyle: {
          backgroundColor: 'black',
        },
        headerTintColor: tw.color('white'),
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        // headerShown: false, // si se quiere escondido
      }}
    >
      {auth.isLogged ? (
        <>
          {/* 
            Rutas protegidas
          */}
          <Stack.Screen
            name="Rewards.Index"
            component={RewardsScreen}
            options={{ headerShown: false }}
          />

          <Stack.Screen
            name="Orders.Index"
            component={OrdersScreen}
            options={{ headerShown: false }}
          />

          <Stack.Screen
            name="Codes.OrderDetail"
            component={OrderDetailScreen}
            options={{ headerShown: false }}
          />

      

          <Stack.Screen
            name="Examples.Form"
            component={FormExampleScreen}
            options={{ title: "Ejemplo Formulario" }}
          />
          

          <Stack.Screen
            name="Business"
            component={BusinessNavigator}
            options={{ headerShown: false }}
          />


          {/* Acá se siguen agregando rutas ...*/}
        </>
      ) : (
        <>
          {/* El stack de Auth */}
          <Stack.Screen
            name="Auth.Login"
            component={LoginScreen}
            options={{ title: "Iniciar sesión" }}
          />
        </>
      )}

      <Stack.Screen
        name="Codes.Detail"
        component={CodeScanDetailScreen}
        options={{ headerShown: false }}
      />



    </Stack.Navigator>
  )
}

function BusinessNavigator() {
  const {selectedBusiness} = useSelector(state => state.status)
  const navigation = useNavigation()
  
  useEffect(() => {
    if(!selectedBusiness){
      navigation.navigate("Auth.Login")
    }
  }, []);
  
  return (
    <Stack.Navigator
      screenOptions={{
        // Ejemplo de customizacion del header
        headerStyle: {
          backgroundColor: 'black',
        },
        headerTintColor: tw.color('white'),
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerTitleAlign:"center",
      }}
    >
      <Stack.Screen
        name="Dashboard"
        component={DashboardScreen}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name="Customers.Index"
        component={CustomersScreen}
        options={{ title: "Clientes" }}
      />

      <Stack.Screen
        name="Feedback.Index"
        component={FeedbackScreen}
        options={{ title: "Reseñas" }}
      />
      

      <Stack.Screen
        name="Codes.Redeems"
        component={CodeRedeemsScreen}
        options={{ title: "Códigos redimidos" }}
      />
      
  

      <Stack.Screen
        name="Customers.Detail"
        component={CustomerDetailScreen}
        options={{ title: "" }}
      />
  

      <Stack.Screen
        name="Campaigns.Index"
        component={CampaignsScreen}
        options={{ title: "Tarjeta de Lealtad" }}
      />


      <Stack.Screen
        name="Codes.New"
        component={NewCodeScreen}
        options={{ title: "Nuevo Código" }}
      />
    </Stack.Navigator>
  );
}



function RootTabNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}




/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
import Colors from '../screens/Examples/TabNavigation/constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import CodeScanDetailScreen from "../screens/Codes/CodeScanDetailScreen";
import OrderDetailScreen from "../screens/Customer/OrderDetailScreen";
import OrdersScreen from "../screens/Customer/OrdersScreen";
import RewardsScreen from "../screens/Customer/RewardsScreen";
import CustomersScreen from "../screens/Business/CustomersScreen";
import CustomerDetailScreen from "../screens/Business/CustomerDetailScreen";
import FeedbackScreen from "../screens/Business/FeedbackScreen";
import NewCodeScreen from "../screens/Business/NewCodeScreen";
import DashboardScreen from "../screens/Business/DashboardScreen";
import CampaignsScreen from "../screens/Business/CampaignsScreen";
import CodeRedeemsScreen from "../screens/Business/CodeRedeemsScreen";

const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="TabOne"
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
      }}>
      <BottomTab.Screen
        name="TabOne"
        component={TabOneScreen}
        options={({ navigation }: RootTabScreenProps<'TabOne'>) => ({
          title: 'Tab One',
          tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
          headerRight: () => (
            <Pressable
              onPress={() => navigation.navigate('Modal')}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <FontAwesome
                name="info-circle"
                size={25}
                color={Colors[colorScheme].text}
                style={{ marginRight: 15 }}
              />
            </Pressable>
          ),
        })}
      />
      <BottomTab.Screen
        name="TabTwo"
        component={TabTwoScreen}
        options={{
          title: 'Tab Two',
          tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}
