const { plugin } = require('twrnc');

module.exports = {
    content: [],
    darkMode: 'media', // or 'media' or 'class'
    theme: {
        screens: {
            sm: '380px',
            md: '420px',
            lg: '680px',
            // or maybe name them after devices for `tablet:flex-row`
            tablet: '1024px',
        },
        extend: {
            colors: {
                primary: '#fe895c',
                secondary: '#ecc94b',

                // Ejemplos:
                // Reescribir algún color base
                red: {
                    DEFAULT: '#f11',
                },
                // Agregar un color entero
                // Una buena página para generar 
                'ocean-green': {
                    DEFAULT: '#48BB78',
                    '50': '#EFF9F3',
                    '100': '#DDF2E6',
                    '200': '#B8E4CA',
                    '300': '#92D7AF',
                    '400': '#6DC993',
                    '500': '#48BB78',
                    '600': '#389860',
                    '700': '#2B7249',
                    '800': '#1D4D31',
                    '900': '#0F2819'
                },
            },
        },
    },
    variants: {
        extend: {},
    },
    plugins: [
        plugin(({ addUtilities }) => {
            addUtilities({
                row: {
                    flexDirection: 'row',
                    alignItems: 'center',
                },
            });
        }),
    ],
}
