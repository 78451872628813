import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';
import { Image } from 'expo-image';
import { useSelector } from 'react-redux';




export default function CustomerDetailScreen({ route, navigation }) {
  const { selectedBusiness } = useSelector(state => state.status)

  const { customerId } = route.params

  const { isLoading, error, data: customer } = useQuery({
    queryKey: ['customerDetail', customerId],
    queryFn: getCustomer,
  })


  async function getCustomer({ queryKey }) {
    const params = {}

    return api.get(`/business/${selectedBusiness.uuid}/customers/${customerId}`, { params })
      .then(response => response.data)
  }

  useEffect(() => {
    navigation.setOptions({
      title: customer?.name ?? "",
    })
  }, [customer]);


  const reviews = customer?.codes?.filter(e => e.purchase?.reviewed_at)


  return (
    <ScrollView style={tw``} contentContainerStyle={tw`px-7 my-8`}>
      {/* <Text style={tw`mt-4 mb-4 text-xl font-bold text-center`}>Detalle cliente</Text> */}
      
      {
        customer &&
          <View>
            {/* <Text style={tw`font-bold text-xl mb-6`}>{customer.name}</Text> */}

            <View style={tw`row justify-between items-start flex-stretch`}>
              {/* ordnes */}
              <View style={tw`flex-1 py-2 px-3 rounded-lg mr-2 bg-blue-200`}>
                <Text style={tw`text-center mb-3 font-500`}>Órdenes:</Text>

                <View style={tw`row justify-around`}>
                  <View>
                    {/* delivary */}
                    <View style={tw`row`}>
                      <Image source={require('../../assets/images/icons/delivery.svg')} style={tw`h-4 w-4 mr-1`} contentFit="contain" />
                      <Text style={tw``}>{customer.total_orders_delivery}</Text>
                    </View>

                    {/* # en lugar */}
                    <View style={tw`row`}>
                      <Image source={require('../../assets/images/icons/en_lugar.svg')} style={tw`h-4 w-4 mr-1`} contentFit="contain" />
                      <Text style={tw``}>{ customer.total_orders_in_place }</Text>
                    </View>
                  </View>

                  <Text style={tw`font-bold text-3xl text-center ml-2`}>{customer.total_orders}</Text>
                </View>
              </View>

              {/* numero de compras */}
              <View style={tw`flex-1 py-2 px-3 rounded-lg ml-2 bg-blue-200`}>
                <Text style={tw`text-center font-500`}>Monto de compras</Text>
                <Text style={tw`font-bold text-2xl mt-3 text-center`}>${customer.total_amount}</Text>
              </View>
            </View>


            {/* Datos cliente */}
            <View style={tw`mt-6`}>
              {
                customer.phone_number &&
                  <View style={tw`row justify-between mb-3`}>
                    <View style={tw`row`}>
                      <Image source={require('../../assets/images/icons/tel.svg')} style={tw`h-5 w-5 mr-2`} contentFit="contain" />

                      <Text style={tw`text-base`}>{customer.phone_number}</Text>
                    </View>

                    <TouchableOpacity style={tw`bg-black rounded-full py-1 px-3`}>
                      <Text style={tw`text-white`}>Llamar</Text>
                    </TouchableOpacity>
                  </View>
              }

              <View style={tw`row`}>
                <View style={tw`row`}>
                  <Image source={require('../../assets/images/icons/correo.svg')} style={tw`h-4 w-4 mr-2`} contentFit="contain" />

                  <Text style={tw`text-base`}>{customer.email}</Text>
                </View>
              </View>
            </View>

            {/* Ultimas compras */}
            <View style={tw`mt-8`}>
              <Text style={tw`mb-2 font-500 text-lg text-center`}>Últimas compras</Text>
           

              {
                customer.codes?.map(code => {
                  const date = new Date(code.created_at)
                  const dateStr = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`

                  return (
                    <View style={tw`row justify-between border-b border-gray-200 py-2`} key={code.uuid}>
                      <View>
                        <Text style={tw`font-500 text-base mr-4`}>#{code.ticket_id}</Text>
                        <Text style={tw`text-base text-gray-500`}>{dateStr}</Text>
                      </View>

                      <View style={tw`items-end`}>
                        {
                          code.type == "delivery" ? 
                            <Image source={require('../../assets/images/icons/delivery.svg')} style={tw`h-4 w-4 mr-2`} contentFit="contain" />
                          :
                            <Image source={require('../../assets/images/icons/en_lugar.svg')} style={tw`h-4 w-4 mr-2`} contentFit="contain" />
                        }
                        <Text style={tw`text-base`}>${code.amount}</Text>
                      </View> 
                    </View> 
                  )
                })
              }
            </View>

            <View style={tw`mt-8`}>
              <Text style={tw`mb-2 font-500 text-lg text-center`}>Reseñas</Text>
              {
                reviews.length == 0 && <Text style={tw`text-center mt-4 text-lg text-gray-400`}>Sin reseñas</Text>
              }

              {
                reviews?.map(order => {
                  const date = new Date(order.created_at)
                  const dateStr = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`

                  return (
                    <View style={tw`py-3 px-3 rounded-lg bg-gray-200 mb-3`} key={order.purchase.reviewed_at}>
                      <View>
                        <Text style={tw`font-500 mb-2`}>{order.purchase.review_comments}</Text>
                        <Text>{dateStr}</Text>
                        <Text style={tw`text-gray-500`}>Orden #{order.ticket_id}</Text>
                      </View>

                      {/* Calificacion resenas */}
                      <View style={tw`row mt-2`}>
                        {/* Comida */}
                        <View style={tw`row`}>
                          <Text style={tw`mr-1`}>Comida</Text>

                          {
                            Array(order.purchase.product_rating).fill("").map(e => 
                              <Image source={require('../../assets/images/icons/estrella.svg')} style={tw`h-4 w-4`} contentFit="contain" />
                            )
                          }
                        </View>

                        {/* Servicio */}
                        <View style={tw`row ml-2`}>
                          <Text style={tw`mr-1`}>Servicio</Text>

                          {
                            Array(order.purchase.service_rating).fill("").map(e => 
                              <Image source={require('../../assets/images/icons/estrella.svg')} style={tw`h-4 w-4`} contentFit="contain" />
                            )
                          }
                        </View>
                      </View>
                    </View> 
                  )
                }
                  
                )
              }
            </View>
           
          </View>
      }

    </ScrollView>
  );
}
