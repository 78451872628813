import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import Text from '../../components/Text';


import { Image } from 'expo-image';
import { useNavigation, useRoute } from '@react-navigation/native';
import ImageCapture from "../../components/ImageCapture"
import { Ionicons } from '@expo/vector-icons';


export default function CustomerTabBar({  }) {
  const navigation = useNavigation()
  const route = useRoute()

  console.log({route});
  

  const [cameraOpen, setCameraOpen] = useState(false);
  

  function onBarcodeScanned(result){
    const domains = ["puntoqr.app", "qr.ouvio.com", "192.168.1.69"]

    console.log("onBarCodeScanned", result);


    if (result.data) {
      const parts = result.data.split("/")

      if (!!domains.find(d => d == parts[2]) && parts[3] == "c") {
        console.log("match", parts[4]);
        navigation.navigate("Codes.Detail", { codeId: parts[4] })
      } else {
        alert("Código inválido")
      }

      setCameraOpen(false)
    }
  }

  return (
    <View style={tw`row justify-between bg-black rounded-full absolute bottom-2 right-2 left-2`}>
      
      {/* Recompensas */}
      <TouchableOpacity
        style={tw.style(`flex-1 `, route.name?.startsWith("Rewards") && `bg-primary rounded-full`)}
        onPress={() => {
          navigation.navigate("Rewards.Index")
        }}
      >
        <Text style={tw.style(`text-center py-4 text-white text-4`, route.name?.startsWith("Rewards") && ``)}>Recompensas</Text>
      </TouchableOpacity>

      {/* tomar QR */}
      <TouchableOpacity
        style={tw`px-3 py-3 rounded-full`}
        onPress={() => {
          setCameraOpen(true)
        }}
      >
        <Ionicons name='qr-code' size={25} color="white" />
      </TouchableOpacity>


      {/* Mis compras */}
      <TouchableOpacity
        style={tw.style(`flex-1 `, route.name?.startsWith("Orders") && `bg-primary rounded-full`)}
        onPress={() => {
          navigation.navigate("Orders.Index")
        }}
      >
        <Text style={tw.style(`text-center py-4 text-white text-4`, route.name?.startsWith("Orders") && ``)}>Mis Compras</Text>
      </TouchableOpacity>


      {/*  */}
      {
        cameraOpen &&
          <ImageCapture
            onRequestClose={() => setCameraOpen(false)}
            onPictureTaken={null}
            onBarCodeScanned={onBarcodeScanned}
          />
      }
    </View>
  );
}
