import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';


import { Image } from 'expo-image';
import Button from '../../components/Forms/Button';

import OnboardingProcess from '../Onboarding/OnboardingProcess';
import { useSelector } from 'react-redux';


export default function CodeScanDetailScreen({ route, navigation }) {
  const { codeId } = route.params

  const [showOnboarding, setShowOnboarding] = useState();
  const {isLogged} = useSelector(state => state.auth)


  const { isLoading, error, data: code } = useQuery({
    queryKey: ['code', codeId],
    queryFn: getCode,
  })


  async function getCode({ queryKey }) {
    const params = {
    }

    console.log("fetching - getDogs");

    return api.get(`/codes/${codeId}`, { params })
      .then(response => response.data)
  }

  async function saveCode() {
    return api.post(`/codes/${codeId}/save`)
      .then(response => response.data)
      .then(data => {
        navigation.navigate('Codes.OrderDetail', { codeId: code.uuid })
      })
      .catch(({response}) => {
        console.error(response);
      })
  }


  useEffect(() => {
    if (code?.redeemed) {
      navigation.navigate('Codes.OrderDetail', { codeId: code.uuid })
    }
  }, [code]);




  if (isLoading) return <Text style={tw`text-lg text-gray-600 mt-10 text-center`}>Cargando...</Text>

  if (error) return <Text>Ocurrió un error: {error.message}</Text>

  return (
    <ScrollView style={tw``} contentContainerStyle={tw`px-9 my-2`}>
      {
        code &&
        <View>

          {
            !showOnboarding ?
              <View>
                <Text style={tw`mt-8 mb-4 text-2xl text-center font-bold text-gray-500`}>Tenemos un regalo</Text>
                
                {/* Negocio */}
                <View style={tw`items-center mb-10 mt-10`}>
                  {
                    code.business.image_path &&
                      <Image source={code.business.image_path} style={tw`h-20 w-20`} contentFit='contain' />
                  }
                  <Text style={tw`font-bold text-xl mt-3`}>{code.business.name}</Text>
                </View>

                <Text style={tw`mt-5 text-center text-lg mb-10`}>{code.discount_description}</Text>

                {
                  isLogged ? 
                    <View>
                      <Button label='Guardar código' style={{ container: tw`mt-10` }} onPress={saveCode} />
                    </View>
                  :
                    <View>
                      <Text style={tw`mb-4 leading-3 text-base text-center font-600`}>Bienvenid@ al programa de recompensas de <Text style={tw`font-800`}>{code.business.name}</Text>, regístrate y gana descuentos por tus compras.</Text>
                      <Text style={tw`mb-4 leading-3 text-base text-center font-600`}>Comparte opiniones para acercarte más a vuestras tiendas favoritas.</Text>
                      {/* <Text>{code.uuid}</Text> */}
                      {/* <Text>{code.ticket_id}</Text> */}
                      {/* <Text>${code.amount}</Text> */}

                      {/* <Camera style={tw`h-40 bg-red`} type={CameraType.back}></Camera> */}
                      <Button label='Registrarme' style={{ container: tw`mt-10` }} onPress={() => setShowOnboarding(true)} />
                    </View>
                }
              </View>
              :
              <OnboardingProcess code={code} />
          }
        </View>
      }
    </ScrollView>
  );
}
