import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';


import { Image } from 'expo-image';
import { useSelector } from 'react-redux';



export default function CustomersScreen({ route, navigation }) {
  // const { codeId } = route.params
  const { selectedBusiness } = useSelector(state => state.status)

  const { isLoading, error, data: customers } = useQuery({
    queryKey: ['businessCustomers'],
    queryFn: getCustomers,
  })


  async function getCustomers({ queryKey }) {
    const params = {}

    return api.get(`/business/${selectedBusiness.uuid}/customers`, { params })
      .then(response => response.data)
  }



  // if (isLoading) return <Text style={tw`text-lg text-gray-600 mt-10 text-center`}>Cargando...</Text>

  // if (error) return <Text>Ocurrió un error: {error.message}</Text>

  return (
    <ScrollView style={tw``} contentContainerStyle={tw`px-3 my-8`}>
      {/* <Text style={tw`mt-4 mb-4 text-xl font-bold text-center`}>Mis clientes</Text> */}
      
      <View style={tw`bg-white rounded-xl py-1`}>
        {
          isLoading && <Text style={tw`text-lg text-gray-600 my-10 text-center`}>Cargando...</Text>
        }
        {
          customers?.map(customer => {
            const lastOrderDate = new Date(customer.last_order_at)
            const dateStr = `${lastOrderDate.getDate()}/${lastOrderDate.getMonth()}/${lastOrderDate.getFullYear()} ${lastOrderDate.getHours()}:${lastOrderDate.getMinutes()}`

            return (
              <TouchableOpacity
                key={customer.uuid}
                style={tw`pt-2 px-6`}
                onPress={() => navigation.navigate("Customers.Detail", {customerId: customer.uuid})}
              >
                <View style={tw`row justify-between`}>
                  <Text style={tw`font-500 text-lg`}>{customer.name}</Text>
                  <Text style={tw`text-base text-gray-500`}>{customer.total_orders} compra{customer.total_orders > 1 && 's'}</Text>
                  {/* <Text>Teléfono: {customer.phone_number}</Text> */}
                </View>

                <View style={tw`row justify-between`}>
                  <Text style={tw`text-base  text-gray-500`}>{customer.email}</Text>
                  <Text style={tw`text-lg`}>${customer.total_amount}</Text>
                </View>

                <View style={tw`border-b border-gray-200 mx-0 mt-2`}></View>
                {/* <Text>{JSON.stringify(customer)}</Text> */}
              </TouchableOpacity>
            )
          } )
        }
      </View>
    </ScrollView>
  );
}
