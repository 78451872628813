import React, { useEffect, useState } from 'react';
import { Alert, Platform, ScrollView, TouchableOpacity, View } from 'react-native'


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';
import Input from '../../components/Forms/Input';
import { useForm } from 'react-hook-form';
import { Ionicons } from '@expo/vector-icons';
import Button from '../../components/Forms/Button';
import { Modal } from 'react-native';

import { Camera, CameraType } from 'expo-camera';

import { useRef } from 'react';
import { useSelector } from 'react-redux';


export default function RedeemDiscountModal({ onRequestClose }) {
  const { selectedBusiness } = useSelector(state => state.status)

  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, watch } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const cameraRef = useRef()

  async function redeemCode(code) {
    setSubmitting(true)

    const data = {
      code,
    }

    return api.post(`/business/${selectedBusiness.uuid}/codes/redeem`, data)
      .then(({ data }) => {
        console.log("codes success", data);

        Platform.OS == "web" ?
          alert(`Código redimido correctamente.\nDescuento: ${data.discount_description}`) :
          Alert.alert(`Código redimido correctamente`, `Descuento: ${data.discount_description}`)

        onRequestClose?.()
      })
      .catch(({ response }) => {
        setSubmitting(false)

        console.error("codes error", response.data);
        let errorMsg = "Error al validar el código"

        if (response.data?.message){
          errorMsg = response.data.message
        }

        Platform.OS == "web" ? alert(errorMsg) : Alert.alert(errorMsg)

        onRequestClose?.()
      })
  }

  function onBarCodeScanned({data}){
    console.log(data);
    if(!submitting){
      cameraRef.current?.pausePreview()
      redeemCode(data)
    }
    // cameraRef.current?.resumePreview()
  }

  return (
    <Modal visible={true} onRequestClose={onRequestClose} transparent>
      <TouchableOpacity style={tw`absolute bg-black/50 top-0 right-0 left-0 bottom-0`} onPress={onRequestClose} />

      <View style={tw`absolute top-20 left-5 right-5 bottom-20 px-5 py-5 bg-white rounded-xl`}>
        {
          // (Camera && !submitting) &&
          <Camera
            style={tw`w-full h-full`}
            type={CameraType.back}
            ref={cameraRef}
            // ratio={"1:1"}
            onCameraReady={async () => {
              // requestCameraPermissions()
            }}
            barCodeScannerSettings={{
              barCodeTypes: ["qr"],
            }}
            onBarCodeScanned={onBarCodeScanned}
          // pictureSize={"1500x1500"}
          />
        }


      </View>

    </Modal>
  );
}
