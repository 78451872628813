import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';


import { Camera, CameraType } from 'expo-camera';
import { Image } from 'expo-image';
import Button from '../../components/Forms/Button';
import CustomerTabBar from './CustomerTabBar';



export default function OrdersScreen({ route, navigation }) {
  // const { codeId } = route.params

  const { isLoading, error, data: orders } = useQuery({
    queryKey: ['myOrders'],
    queryFn: getMyOrders,
  })


  async function getMyOrders({ queryKey }) {
    const params = {}

    return api.get(`/me/orders`, { params })
      .then(response => response.data)
  }



  if (isLoading) return <Text style={tw`text-lg text-gray-600 mt-10 text-center`}>Cargando...</Text>

  if (error) return <Text>Ocurrió un error: {error.message}</Text>

  return (
    <View style={tw`flex-1`}>
      <ScrollView style={tw`flex-1`} contentContainerStyle={tw`px-3 my-6`}>
        <Text style={tw`mt-4 mb-6 text-xl font-bold text-center text-primary`}>Mis Compras</Text>
        
        <View style={tw`bg-white rounded-lg`}>
          {
            orders?.map(order => {
              const date = new Date(order.created_at)
              const dateStr = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`

              return (
                <TouchableOpacity
                  key={order.uuid}
                  style={tw`py-2 pl-1 pr-3 mx-2 row`}
                  onPress={() => navigation.navigate("Codes.OrderDetail", {codeId: order.uuid})}
                >
                  <Image source={order.business.image_path} style={tw`h-14 w-14`} contentFit='contain' />

                  <View style={tw`flex-1 ml-3`}>
                    <View style={tw`row justify-between`}>
                      <Text style={tw`text-lg font-600`}>{order.business.name}</Text>
                      <Text style={tw`text-lg text-gray-500`}>#{order.ticket_id}</Text>
                    </View>

                    <View style={tw`row justify-between`}>
                      <Text style={tw`text-lg text-gray-500`}>{dateStr}</Text>
                      <Text style={tw`text-lg`}>${order.amount}</Text>
                    </View>
                  </View>
                  {/* <Text>{JSON.stringify(order)}</Text> */}
                  <View style={tw`border-b border-gray-200`}></View>
                </TouchableOpacity>
              )
            } )
          }
        </View>
      </ScrollView>

      <CustomerTabBar />
    </View>
  );
}
