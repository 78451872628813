import { combineReducers, applyMiddleware } from 'redux'
import { createStore } from 'redux'
import thunk from 'redux-thunk'

// Reductores...
import {reducer as UserRedux} from './Reducers/auth';
import { reducer as StatusRedux } from './Reducers/status';


const todoApp = combineReducers({
    auth: UserRedux,
    status: StatusRedux,
})


// Persist config
import { persistStore, persistReducer } from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage';


const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    blacklist: [],
}




const persistedReducer = persistReducer(persistConfig, todoApp)



export default () => {
    let store = createStore(persistedReducer, applyMiddleware(thunk))
    let persistor = persistStore(store)
    return { store, persistor }
}