import React, { useEffect, useState } from 'react';
import { Modal, ScrollView, TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';
import CustomerTabBar from './CustomerTabBar';
import { Image } from 'expo-image';
import { SwiperFlatList } from 'react-native-swiper-flatlist';
import { useSelector } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';


function CouponRedeemModal({coupon, onRequestClose}){
  return (
    <Modal visible={true} transparent onRequestClose={onRequestClose}>
      <TouchableOpacity style={tw`absolute top-0 left-0 right-0 bottom-0 bg-black/35`} onPress={onRequestClose} />

      <View style={tw`absolute top-20% left-10 right-10 bottom-20% items-center justify-center rounded-xl`}>
        <View style={tw.style(`absolute top-0 left-0 right-0 bottom-0`, { transform: [{ scale: 1.1 }] })}>
          <Image
            source={{
              uri: require("./fondo_qr.svg"),
            }}
            style={tw.style(`w-full h-full`)}
            contentFit="cover"
            contentPosition={"top"}
          /> 
        </View>

        <View style={tw`z-10 items-center px-4`}>
          <Image source={coupon.business_image_url} style={tw`w-20 h-20 rounded-lg mb-10`} contentFit="contain" />

          <Image source={coupon.qr_url} style={tw`h-50 w-50`} contentFit="contain" />

          <Text style={tw`text-center text-lg font-500 mt-4 leading-6`}>{coupon.description}</Text>

          <Text style={tw`text-center text-lg mt-4`}>{coupon.code}</Text>
        </View>
      </View>
    </Modal>
  )
}


export default function RewardsScreen({ route, navigation }) {
  const userBusiness = useSelector(state => state.status?.business)

  const { isLoading, error, data: rewards, refetch } = useQuery({
    queryKey: ['rewards'],
    queryFn: () => api.get(`/me/rewards`).then(response => response.data),
  })



  const [shownCoupon, setShownCoupon] = useState();

  function showCoupon(coupon){
    setShownCoupon(coupon)
  }

  return (
    <View style={tw`flex-1`}>
      <ScrollView style={tw`flex-1`} contentContainerStyle={tw`my-5 flex-1`}>
        <Text style={tw`mt-4 mb-4 text-xl font-bold text-center text-primary`}>Mis Recompensas</Text>

        {
          userBusiness?.length > 0 &&
            <TouchableOpacity
              onPress={() => navigation.navigate("Business")}
              style={tw`absolute right-4 top-0 bg-primary rounded-full items-center justify-center h-8 w-8`}
            >
              <Ionicons name={"business-outline"} size={16} color="white" />
            </TouchableOpacity>
        }


        {/* Rere */}
        <View style={tw.style(`px-2`, rewards?.loyalty?.length > 0 && `pb-[30px]`)}>
          <SwiperFlatList showPagination={rewards?.loyalty?.length > 0} paginationStyle={tw`bottom-[-16px]`}>
            {
              rewards?.loyalty.map((card, i) => {
                return (
                  <View style={tw`mx-4 w-[90vw]`}>
                    <View
                      key={i}
                      style={tw`h-46 rounded-xl overflow-hidden items-center justify-center px-7 bg-gray-500`}
                    >
                      {/* Imagen fondo */}
                      <View style={tw` absolute top-0 left-0 right-0 bottom-0`}>
                        <Image source={card.image_url} style={tw`w-full h-full`} contentFit="cover" />
                      </View>

                      {/* Estrellas */}
                      <View style={tw`row flex-wrap gap-x-11 gap-y-6 justify-center`}>
                        {
                          Array(card.goal).fill("").map((_, i) => 
                            <View
                              key={i}
                              style={tw.style(`bg-black rounded-full h-8 w-8 items-center justify-center`, i < card.current && `bg-gray-500`)}
                            >
                              <Text style={tw`text-white text-lg font-500`}>{i+1}</Text>
                              {
                                i < card.current &&
                                  <View style={tw.style(
                                    `absolute top-0`,
                                    {
                                      transform: [
                                        { rotate: `${i * 2 * (i % 2 ? 1 : -1)}deg`},
                                        { translateY: (i % 2 ? 2 : -2) },
                                        { translateX: (i % 2 ? -2 : 2) }
                                      ],
                                    }
                                  )}
                                  >
                                    <Image
                                      source={require('../../assets/images/icons/estrella.svg')}
                                      style={tw.style(
                                        `h-9 w-9`,
                                      )}
                                      contentFit="contain"
                                    />
                                  </View>

                              }
                            </View>
                          )
                        }
                      </View>
                    </View>

                    <Text style={tw`text-center text-base text-gray-400 px-2 mt-4 leading-5`}>{card.discount_description}</Text>
                  </View>
                )
              })
            }
          </SwiperFlatList>
        </View>

        <View style={tw`border-t-4 border-dashed border-gray-200 mx-6 mt-6`}></View>

        <View style={tw`px-6 mt-6`}>
          {
            rewards?.coupons?.active?.map((coupon, i) => {
              return (
                <TouchableOpacity
                  key={i}
                  disabled={coupon.redeemed}
                  onPress={() => showCoupon(coupon)}
                  style={tw`bg-white px-4 py-3 row mb-4 shadow-lg rounded-sm`}
                >
                  <Image source={coupon.business_image_url} style={tw`w-16 h-16 rounded-lg`} contentFit="contain" />

                  <View style={tw`ml-3 flex-1`}>
                    <View style={tw`row`}>
                      <Text style={tw`text-lg font-500`}>{coupon.code}</Text>

                      {
                        coupon.redeemed &&
                          <Text style={tw`ml-3 font-500 text-primary font-500`}>Canjeado</Text>
                      }
                    </View>
                    <Text style={tw`leading-4 font-500 text-gray-600`}>{coupon.description}</Text>
                  </View>
                </TouchableOpacity>
              )
            })
          }
        </View>


        {/* <Image
          source={{
            uri: require("./rewards.png"),
            // width: '100%',
            // height: imgHeight
          }}
          style={tw`w-full h-[500]`}
          onLoad={(ev) => {
            // if(!imgHeight)
            // setImgHeight(ev.source.height)
          }}
          contentFit="cover"
          contentPosition={"top"}
          
        /> */}


      </ScrollView>

      {
        shownCoupon &&
          <CouponRedeemModal coupon={shownCoupon} onRequestClose={() => setShownCoupon(null)}/>
      }

      <CustomerTabBar />
    </View>
  );
}
