import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';
import { Image } from 'expo-image';
import { useSelector } from 'react-redux';




export default function CodeRedeemsScreen({ route, navigation }) {
  const { selectedBusiness } = useSelector(state => state.status)

  const { isLoading, error, data: orders } = useQuery({
    queryKey: ['codeRedeems'],
    queryFn: getCodeRedeems,
  })


  async function getCodeRedeems({ queryKey }) {
    const params = {}

    return api.get(`/business/${selectedBusiness.uuid}/codes/redeemed`, { params })
      .then(response => response.data)
  }



  // if (isLoading) return <Text style={tw`text-lg text-gray-600 mt-10 text-center`}>Cargando...</Text>

  // if (error) return <Text>Ocurrió un error: {error.message}</Text>


  return (
    <ScrollView style={tw``} contentContainerStyle={tw`px-7 my-8`}>
      {/* <Text style={tw`mt-4 mb-4 text-xl font-bold text-center`}>Reseñas</Text> */}
      
      {
        orders?.map(code => {
          const orderDate = new Date(code.redeemed_at)
          const dateStr = `${orderDate.getDate()}/${orderDate.getMonth()}/${orderDate.getFullYear()}` //  ${orderDate.getHours()}:${orderDate.getMinutes()}

          return (
            <View
              key={code.uuid}
              style={tw`py-3 bg-white px-3 mb-3 rounded-lg`}
              // onPress={() => navigation.navigate("Customers.Detail", { customerId: code.uuid })}
            >
              <View style={tw`row justify-between border-b border-gray-200 pb-2`}>
                <View style={tw`row justify-between flex-1`}>
                  <Text style={tw`font-bold mr-3`}>{code.uuid}</Text>
                  <Text style={tw`text-gray-500`}>{dateStr}</Text>
                </View>
                    
                {/* {
                  code.type == "delivery" ?
                    <Image source={require('../../assets/images/icons/delivery.svg')} style={tw`h-4 w-4 mr-2`} contentFit="contain" />
                    :
                    <Image source={require('../../assets/images/icons/en_lugar.svg')} style={tw`h-4 w-4 mr-2`} contentFit="contain" />
                } */}
              </View>

              {/* <Text>{code.delivery_platform}</Text> */}

              

              {/* Comentario */}
              <View style={tw`row mt-3 items-start`}>

                <View style={tw`flex-1`}>
                  <Text style={tw`text-gray-600 mb-2 font-500`}>{code.customer[0]?.name}</Text>
                  <Text style={tw`text-gray-400 leading-4`}>{code.discount_description}</Text>
                </View>
              </View>
            </View>
          )
        })
      }

    </ScrollView>
  );
}
