import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';


import { Camera, CameraType } from 'expo-camera';
import { Image } from 'expo-image';
import Button from '../../components/Forms/Button';
import Input from '../../components/Forms/Input';
import { useForm } from 'react-hook-form';

import Rating from '../../components/Rating';


function OrderRating({order, onReviewCreated}){
  const [submitting, setSubmitting] = useState(false);
  const [foodRating, setFoodRating] = useState(3);
  const [serviceRating, setServiceRating] = useState(3);


  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, watch } = useForm();

  function submitReview(data){
    const _data = {
      ...data,
      food_rating: foodRating,
      service_rating: serviceRating
    }

    setSubmitting(true)

    api.post(`/orders/${order.uuid}/review`, _data)
    .then(({data}) => {
      onReviewCreated?.()
    })
    .catch(({response}) => {
      setSubmitting(true)

    })
  }

  const purchase = order.customer?.[0]?.purchase


  return (
    <View style={tw`w-full`}>
      {
        ! purchase?.reviewed_at &&
          <View>
              
            <Text style={tw`text-center mb-5 text-xl font-600`}>Queremos saber tu opinión</Text>

            <View style={tw`items-center w-full`}>
              <View style={tw`mb-8`}>
                <Text style={tw`font-bold text-center text-gray-400 text-lg mb-3`}>Calificación comida</Text>

                {/* <Rating
                  showRating
                  onFinishRating={ratingCompleted}
                  style={{ paddingVertical: 10 }}
                  ratingBackgroundColor={"transparent"}
                /> */}

                <Rating
                  defaultRating={foodRating}
                  onFinishRating={rating => setFoodRating(rating)}
                />
              </View>

              <View style={tw`mb-8`}>
                <Text style={tw`font-bold text-center text-gray-400 text-lg mb-3`}>Calificación servicio</Text>

                <Rating
                  defaultRating={serviceRating}
                  onFinishRating={rating => setServiceRating(rating)}
                />
              </View>

              
              <Input
                name='comments'
                style={{container: tw`w-full`, input: `text-lg`}}
                control={control}
                multiline
                numberOfLines={3}
                placeholder={"Algún comentario extra"}
              />

              <Button disabled={submitting} label='Dejar reseña' style={{ container: tw`mt-5` }} onPress={handleSubmit(submitReview)} />
            </View>
          </View>
      }

      {
        purchase?.reviewed_at &&
          <View>
            <Text style={tw`text-center mb-5 text-xl font-600`}>¡Gracias por tu opinión!</Text>

            <View style={tw`items-center row justify-between mb-6`}>
              <View style={tw`flex-1 mr-1`}>
                <Text style={tw`font-bold text-center text-gray-400 mb-3`}>Comida</Text>

                <Rating
                  isDisabled
                  size={6}
                  showRating={false}
                  defaultRating={purchase.product_rating}
                />
              </View>

              <View style={tw`flex-1 ml-1`}>
                <Text style={tw`font-bold text-center text-gray-400 mb-3`}>Servicio</Text>

                <Rating
                  isDisabled
                  size={6}
                  showRating={false}
                  defaultRating={purchase.service_rating}
                />
              </View>
            </View>

            {
              purchase.review_comments &&
                <Text style={tw`text-lg text-gray-500 text-center`}>{purchase.review_comments}</Text>
            }
          </View>
      }
    </View>
  )
}



export default function OrderDetailScreen({ route, navigation }) {
  const { codeId } = route.params

  const { isLoading, error, data: code, refetch } = useQuery({
    queryKey: ['code', codeId],
    queryFn: getCode,
  })


  async function getCode({ queryKey }) {
    const params = { }

    return api.get(`/codes/${codeId}`, { params })
      .then(response => response.data)
  }
  

  if (isLoading) return <Text style={tw`text-lg text-gray-600 mt-10 text-center`}>Cargando...</Text>

  if (error) return <Text>Ocurrió un error: {error.message}</Text>

  return (
    <ScrollView style={tw``} contentContainerStyle={tw`px-10 my-10`}>
      {/* Botón atrás */}
      <TouchableOpacity style={tw`mb-4`} onPress={() => navigation.navigate("Orders.Index")}>
        <Text>{`<`} Mis Compras</Text>
      </TouchableOpacity>


      {
        code &&
        <View>
          <View style={tw`items-center mb-10 mt-2`}>
            <Image source={code.business.image_path} style={tw`h-20 w-20`} contentFit='contain' />
            <Text style={tw`font-bold text-xl mt-3`}>{code.business.name}</Text>
          </View>
         
          <View style={tw`items-center mb-10`}>
            <Text style={tw`text-center text-lg text-gray-500 mb-4`}>orden #<Text style={tw`font-600 text-black`}>{code.ticket_id}</Text></Text>

            <Text style={tw`text-15 font-600 text-center mb-4`}>${code.amount}</Text>

            <Text style={tw`text-center text-lg text-black font-600`}>{code.type}</Text>
          </View>

          <OrderRating order={code} onReviewCreated={refetch}/>
        </View>
      }
    </ScrollView>
  );
}
