import React, { useEffect, useState } from 'react';
import { Pressable, ScrollView, TouchableOpacity, View } from 'react-native'


import tw from '../styles/tailwind';
import Text from '../components/Text';


import { Image } from 'expo-image';



export default function Rating({ defaultRating, onFinishRating, size=10, stars=5, isDisabled }){
  const [value, setValue] = useState(defaultRating);

  function onPress(i){
    setValue(i)
    onFinishRating(i)
  }

  return (
    <View style={tw`row justify-center`}>
      {
        Array(stars).fill("").map((_, i) =>
          <Pressable key={i} onPress={() => onPress(i+1)} disabled={isDisabled}>
            <Image source={i < value ? require("./images/star-selected.png") : require("./images/star.png")} style={tw`h-${size} w-${size}`} />
          </Pressable>
        )
      }
    </View>
  )
}