import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';
import { Image } from 'expo-image';
import Input from '../../components/Forms/Input';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../components/Forms/Button';
import { Switch } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { useSelector } from 'react-redux';

function NumericSelector({ value=8, onChange }) {
  const [currentValue, setCurrentValue] = useState(value);

  const handleMinus = () => {
    const newValue = currentValue - 1;
    if (newValue >= 0) {
      setCurrentValue(newValue);
      onChange(newValue);
    }
  };

  const handlePlus = () => {
    const newValue = currentValue + 1;
    setCurrentValue(newValue);
    onChange(newValue);
  };

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <TouchableOpacity onPress={handleMinus} style={tw`h-9 w-9 bg-black rounded-full items-center justify-center`}>
        <Text style={tw`text-2xl text-white`}>-</Text>
      </TouchableOpacity>

      <Text style={tw`text-2xl mx-4 font-600`}>{currentValue}</Text>

      <TouchableOpacity onPress={handlePlus} style={tw`h-9 w-9 bg-black rounded-full items-center justify-center`}>
        <Text style={tw`text-2xl text-white`}>+</Text>
      </TouchableOpacity>
    </View>
  );
}


export default function CampaignsScreen({ route, navigation }) {
  const { selectedBusiness } = useSelector(state => state.status)

  const { control, handleSubmit, formState, formState: { errors }, reset, setValue, watch } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const toast = useToast()

  const loyaltyActive = watch("loyalty_active")
  const benefitGoal = watch("benefit_goal", 8)

  const { isLoading, error, data: campaigns } = useQuery({
    queryKey: ['businessCampaigns'],
    queryFn: () => api.get(`/business/${selectedBusiness.uuid}/campaigns`).then(response => response.data),
  })


  async function submitForm(data) {
    console.log(data);

    setSubmitting(true)

    return api.post(`/business/${selectedBusiness.uuid}/loyalty`, data)
      .then(response => response.data)
      .then(data => {
        setSubmitting(false)
        toast.show("Tarjeta de lealtad guardada")
      })
      .catch(({response}) => {
        setSubmitting(false)
      })
  }

  useEffect(() => {
    if(campaigns?.loyalty){
      reset(campaigns?.loyalty)
    }
  }, [campaigns]);



  // if (isLoading) return <Text style={tw`text-lg text-gray-600 mt-10 text-center`}>Cargando...</Text>

  // if (error) return <Text>Ocurrió un error: {error.message}</Text>


  return (
    <ScrollView style={tw``} contentContainerStyle={tw`px-7 my-8`}>
      {/* Fondo gris */}
      <View style={tw`bg-gray-200 rounded-b-2xl -mx-7 -mt-8 pt-8 px-7 pb-4 mb-5`}>
        {/* Titulo tarjeta de lealtad / [switch] */}
        <View style={tw`row justify-between mb-5`}>
          <Text style={tw`text-center  font-500 text-lg`}>Tarjeta de Lealtad</Text>

          <Controller
            control={control}
            name={"loyalty_active"}
            render={({ field: { onChange, onBlur, value } }) => (
              <Switch
                trackColor={{ false: '#FFFFFF', true: '#767577' }}
                thumbColor={loyaltyActive ? '#000' : '#666'}
                ios_backgroundColor="#3e3e3e"
                onValueChange={onChange}
                value={value}
              />
            )}
          />
        </View>

        {
          loyaltyActive &&
          <>
            {/* Placeholder tarjeta */}
            <View
              style={tw`h-46 rounded-xl overflow-hidden items-center justify-center px-7 bg-gray-400 mb-6`}
            >
              {/* Imagen fondo */}
              {/* <View style={tw` absolute top-0 left-0 right-0 bottom-0`}>
                <Image source={card.image_url} style={tw`w-full h-full`} contentFit="cover" />
              </View> */}

              {/* Estrellas */}
              <View style={tw`row flex-wrap gap-x-11 gap-y-6 justify-center`}>
                {
                  Array(benefitGoal).fill("").map((_, i) =>
                    <View
                      key={i}
                      style={tw.style(`bg-gray-700 rounded-full h-8 w-8 items-center justify-center`)}
                    >
                      <Text style={tw`text-white text-lg font-500`}>{i + 1}</Text>
                    </View>
                  )
                }
              </View>
            </View>
          </>
        }
      </View>

      {
        loyaltyActive &&
          <>
            <Text style={tw`text-base font-600 mb-3`}>Beneficio:</Text>
            <Input
              control={control}
              name="discount_description"
              placeholder='Ej: 10% de descuento en tu próxima compra'
              rules={{required: "Requerido"}}
            />


            <Text style={tw`text-base font-600 mb-4`}>Compras para desbloquear beneficio:</Text>

            <View style={tw`items-center mb-6`}>
              <Controller
                control={control}
                name={"benefit_goal"}
                render={({ field: { onChange, onBlur, value } }) => (
                  <NumericSelector value={value} onChange={onChange} />
                )}
              />
            </View>
          </>
      }

      <Button
        disabled={submitting}
        label={submitting ? 'Guardando' : 'Guardar'}
        style={{container: tw`mt-10`}}
        onPress={handleSubmit(submitForm)}
      />

    </ScrollView>
  );
}
