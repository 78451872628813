import React, { useEffect, useState } from 'react';
import { ScrollView, Switch, View } from 'react-native'


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';


import { Image } from 'expo-image';
import Button from '../../components/Forms/Button';

import { useForm } from 'react-hook-form';


export default function FinalStep({ code, onSubmit }) {
  const [submitting, setSubmitting] = useState();

  const [receiveEmails, setReceiveEmails] = useState(true);

  return (
    <View>
      <Text style={tw`text-lg mb-10 text-center font-600 leading-5`}>Código redimido, sigue registrando cada compra y recibe más recompensas.</Text>

      <View style={tw`row justify-between mb-5 text-lg`}>
        <Text style={tw`flex-1 mr-8 text-lg font-600 leading-5`}>Notificarme de novedades y promociones</Text>
        
        <Switch
          trackColor={{ false: '#FFFFFF', true: '#767577' }}
          thumbColor={receiveEmails ? '#000' : '#666'}
          ios_backgroundColor="#3e3e3e"
          onValueChange={setReceiveEmails}
          value={receiveEmails}
          style={tw`absolute right-1`}
        />
      </View>

      {/* <View style={tw`row justify-between mb-5 text-lg`}>
        <Text>Push notifications:</Text>
        <Text>[Switch]</Text>
      </View> */}

      <Button
        disabled={submitting}
        label='Terminar'
        style={{ container: tw`mt-10` }}
        onPress={onSubmit}
      />
    </View>
  )
}