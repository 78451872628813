import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native'


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';


import { Image } from 'expo-image';
import Button from '../../components/Forms/Button';
import Input from '../../components/Forms/Input';
import { useForm } from 'react-hook-form';
import { actions } from '../../store/Reducers/auth';
import { useDispatch, useSelector } from 'react-redux';


const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/




const registerFields = [
  { name: 'name', placeholder: 'Dinos tu nombre', rules: { required: "Nombre requerido" } },
  { name: 'email', placeholder: 'tu correo electrónico', rules: { required: "Captura tu correo electrónico", pattern: { value: emailPattern } }, keyboardType: "email-address" },
  { name: 'phone_number', placeholder: 'tu teléfono', rules: { required: "Captura tu teléfono", pattern: { value: /^\d{10}$/i, message: "Tiene que ser de 10 dígitos" } }, keyboardType: "phone-pad", extra: { maxLength: 10 } },
  { name: 'password', placeholder: 'define una contraseña', rules: { required: "Captura una contraseña" }, extra: { secureTextEntry: true } },
]

export default function RegisterForm({ code, onRegister }) {
  const [currentFieldIndex, setCurrentFieldIndex] = React.useState(0);
  const [submitting, setSubmitting] = useState(false);
  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, watch } = useForm();
  const dispatch = useDispatch()

  const auth = useSelector(state => state.auth)


  useEffect(() => {
    if (auth.token) {
      onRegister?.()
    }
  }, []);

  function nextStep(data) {
    console.log(data);

    if (currentFieldIndex + 1 < registerFields.length) {
      setCurrentFieldIndex(currentFieldIndex + 1)
    } else {
      registerUser(data)
    }
  }

  function registerUser(data) {
    setSubmitting(true)

    const _data = {
      ...data,
      code_id: code.uuid,
    }

    api.post(`/auth/register`, _data)
      .then(({ data }) => {
        // console.log("register success", data);
        dispatch(actions.login({}, data.token))
        onRegister?.()
      })
      .catch(({ response }) => {
        setSubmitting(false)
        console.error("register error", response.data);
      })
  }

  return (
    <View>
      {
        registerFields[currentFieldIndex] &&
        <Input
          key={registerFields[currentFieldIndex].name}
          name={registerFields[currentFieldIndex].name}
          control={control}
          placeholder={registerFields[currentFieldIndex].placeholder}
          keyboardType={registerFields[currentFieldIndex].keyboardType}
          rules={registerFields[currentFieldIndex].rules}
          style={{input: `text-center text-lg`}}
          autoFocus
          {...registerFields[currentFieldIndex].extra}
        />
      }

      <Button disabled={submitting} label='Continuar' style={{ container: tw`mt-5` }} onPress={handleSubmit(nextStep)} />

      <View style={tw`mt-12 mx-3`}>
        <Text style={tw`text-center font-600`}>Al registrarte a nuestro servicio aceptas nuestro <Text>Términos y condiciones</Text> y <Text>Política de privacidad</Text></Text>
        <Text style={tw`text-center font-600`}>Algunos de tus datos serán compartidos con <Text style={tw`font-bold`}>{code.business.name}</Text></Text>
      </View>

    </View>
  )
}

