import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useQuery } from '@tanstack/react-query';


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';
import { Image } from 'expo-image';
import RedeemDiscountModal from './RedeemDiscountModal';
import { useSelector } from 'react-redux';




export default function DashboardScreen({ route, navigation }) {
  const [showRedeemCodeModal, setShowRedeemCodeModal] = useState(false);
  const { selectedBusiness } = useSelector(state => state.status)
  const userBusiness = useSelector(state => state.status?.business)

  const { isLoading, error, data: dashboard } = useQuery({
    queryKey: ['businessDashboard'],
    queryFn: getDashboard,
  })


  async function getDashboard({ queryKey }) {
    const params = {}

    return api.get(`/business/${selectedBusiness.uuid}/dashboard`, { params })
      .then(response => response.data)
  }

  return (
    <ScrollView style={tw``} contentContainerStyle={tw`px-7 my-2`}>
      <Text style={tw`mt-6 mb-1 text-xl font-bold text-center`}>Bienvenido de vuelta</Text>
      <Text style={tw`mb-10 text-center`}>{userBusiness[0].name}</Text>

      
      <View style={tw`row justify-between items-start`}>
        <View style={tw`flex-1 mr-3`}>
          {/* Generar codigo */}
          <TouchableOpacity style={tw`bg-black px-3 pt-4 pb-5 rounded-lg shadow-lg mb-6 items-center`} onPress={() => navigation.navigate("Codes.New")}>
            <Text style={tw`text-primary text-xl font-600 text-center mb-4`}>Generar QR</Text>

            <Image source={require('../../assets/images/icons/qr.svg')} style={tw`h-18 w-18`} contentFit="contain" />
          </TouchableOpacity>



          {/* Resenas */}
          <TouchableOpacity style={tw`px-3 pt-4 pb-5 rounded-lg shadow-lg mb-6 items-center bg-white`} onPress={() => navigation.navigate("Feedback.Index")}>
            <Text style={tw`text-primary text-xl font-600 text-center mb-4`}>Reseñas</Text>

            <Image source={require('../../assets/images/icons/d_rev.svg')} style={tw`h-18 w-18 mb-4`} contentFit="contain" />

            <View style={tw`row mb-2`}>
              <Image source={require('../../assets/images/icons/estrella.svg')} style={tw`h-5 w-5 mr-2`} contentFit="contain" />

              <Text style={tw`text-base`}>{dashboard?.reviews_avg_product ? dashboard?.reviews_avg_product.toFixed(1) : "-"}/5</Text>
              <Text style={tw`ml-1 text-xs text-gray-400`}>(promedio)</Text>
            </View>

            <View style={tw`row`}>
              <Image source={require('../../assets/images/icons/rev.svg')} style={tw`h-5 w-5 mr-2`} contentFit="contain" />

              <Text style={tw`text-base`}>{dashboard?.reviews_count ?? "-"} reseñas</Text>
            </View>
          </TouchableOpacity>


          {/* Redimir codigo */}
          <TouchableOpacity
            style={tw`bg-white px-3 pt-4 pb-5 rounded-lg shadow-lg mb-6 items-center`}
            onPress={() => setShowRedeemCodeModal(true)}
          >
            <Text style={tw`text-primary text-xl font-600 text-center mb-4`}>Redimir descuento</Text>

            <Image source={require('../../assets/images/icons/qr.svg')} style={tw`h-18 w-18`} contentFit="contain" />
          </TouchableOpacity>
        </View>


        <View style={tw`flex-1 ml-3`}>
          {/* Clientes */}
          <TouchableOpacity style={tw`px-3 pt-4 pb-5 rounded-lg shadow-lg mb-6 items-center bg-white`} onPress={() => navigation.navigate("Customers.Index")}>
            <Text style={tw`text-primary text-xl font-600 text-center mb-4`}>Clientes</Text>

            <Image source={require('../../assets/images/icons/d_clientes.svg')} style={tw`h-18 w-18 mb-4`} contentFit="contain" />

            <Text style={tw`text-base text-center`}><Text style={tw`text-xl text-primary font-500`}>{dashboard?.customers_count ?? "-"}</Text> clientes</Text>
            <Text style={tw`text-base text-center`}><Text style={tw`text-xl text-primary font-500`}>{dashboard?.orders_count ?? "-"}</Text> órdenes</Text>
            <Text style={tw`text-base text-center`}><Text>${dashboard?.order_avg_amount ? dashboard?.order_avg_amount.toFixed(1) : "-"}</Text> <Text style={tw`text-sm text-gray-400`}>(promedio)</Text></Text>
          </TouchableOpacity>


          {/* Campañas */}
          <TouchableOpacity style={tw`px-3 pt-4 pb-5 rounded-lg shadow-lg mb-6 items-center bg-white`} onPress={() => navigation.navigate("Campaigns.Index")}>
            <Text style={tw`text-primary text-xl font-600 text-center mb-4`}>Tarjeta de Lealtad</Text>

            <Image source={require('../../assets/images/icons/d_camp.svg')} style={tw`h-18 w-18`} contentFit="contain" />
          </TouchableOpacity>

          {/* Campañas */}
          <TouchableOpacity style={tw`px-3 pt-4 pb-5 rounded-lg shadow-lg mb-6 items-center bg-white`} onPress={() => navigation.navigate("Codes.Redeems")}>
            <Text style={tw`text-primary text-xl font-600 text-center mb-4`}>Códigos redimidos</Text>

            {/* <Image source={require('../../assets/images/icons/d_camp.svg')} style={tw`h-18 w-18`} contentFit="contain" /> */}
          </TouchableOpacity>
        </View>
      </View>
      

      {
        showRedeemCodeModal &&
          <RedeemDiscountModal onRequestClose={() => setShowRedeemCodeModal(false)} />
      }

     
    </ScrollView>
  );
}
