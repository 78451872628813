import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native'


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';


import { Image } from 'expo-image';
import ActivateNotifications from '../Onboarding/ActivateNotifications';
import RegisterForm from '../Onboarding/RegisterForm';
import InstallWebApp from '../Onboarding/InstallWebApp';
import { useNavigation } from '@react-navigation/native';
import FinalStep from './FinalStep';


export default function OnboardingProcess({ code }) {
  const [step, setStep] = useState(0);
  const navigation = useNavigation()

  function onRegister() {
    if(step == 2){
      navigation.navigate("Codes.OrderDetail", {codeId: code.uuid})
    }else{
      setStep(step + 1)
    }
  }

  // <ActivateNotifications code={code} onSubmit={onRegister} />
  //
  return (
    <View>

      <Text style={tw`mt-8 mb-4 text-2xl text-center font-bold text-gray-500`}>{step < 2 ? `Regístrate` : `¡Listo!`}</Text>

      {/* Negocio */}
      <View style={tw`items-center mb-10 mt-6`}>
        <Image source={code.business.image_path} style={tw`h-18 w-18 rounded-lg`} contentFit='contain' />

        <Text style={tw`font-bold text-xl mt-3`}>{code.business.name}</Text>
      </View>

      
      {
        step == 0 &&
          <RegisterForm code={code} onRegister={onRegister} />
      }
      
      {
        step == 1 &&
          <InstallWebApp code={code} onFinish={onRegister} />
      }

      {
        step == 2 &&
          <FinalStep code={code} onSubmit={onRegister} />
      }
    </View>
  )
}