/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';


// Configuración de las URL
// https://reactnavigation.org/docs/deep-linking/
// Nombres de ruta de react Navigation https://reactnavigation.org/docs/configuring-links


const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          TabOne: {
            screens: {
              TabOneScreen: 'one',
            },
          },
          TabTwo: {
            screens: {
              TabTwoScreen: 'two',
            },
          },
        },
      },
      Modal: 'modal',
      NotFound: '*',

      "Examples.APIRequest": 'ejemplos',
      "Codes.Detail": 'c/:codeId',
      "Codes.OrderDetail": 'orden/:codeId',
      "Orders.Index": 'ordenes',
      "Rewards.Index": 'recompensas',

      "Business": {
        screens: {
          "Dashboard": 'negocio',
          "Codes.New": 'negocio/codigo/nuevo',
          "Customers.Index": 'negocio/clientes',
          "Customers.Detail": 'negocio/clientes/:customerId',
          "Feedback.Index": 'negocio/resenas',
          "Campaigns.Index": 'negocio/campanas',
          "Codes.Redeems": 'negocio/codigos-redimidos',
        }
      },

      "Auth.Login": "auth/login",
    },
  },
};

export default linking;
