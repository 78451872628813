import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Modal } from "react-native"

import tw from '../styles/tailwind';
import Text from '../components/Text';


// Camera
import { useRef } from 'react';
import { Camera, CameraType } from 'expo-camera';
import { Platform } from "react-native"


export default function ImageCapture({ onRequestClose, onPictureTaken, onBarCodeScanned }) {
  const [selectedImage, setSelectedImage] = useState();
  const [uploadPercentage, setUploadPercentage] = useState();
  const [uploading, setUploading] = useState();
  const [percentCompleted, setPercentCompleted] = useState();

  const [permission, requestPermission] = Camera?.useCameraPermissions();

  const cameraRef = useRef()


  useEffect(() => {
    console.log("camara", { permission });
    
    if (!permission || ! permission.granted) {
      console.log("no permisos, solicitando", permission);

      if(Platform.OS !== "web"){
        requestCameraPermissions()
      }
    }
  }, []);
  

  async function requestCameraPermissions() {
    const pp = await Camera.requestCameraPermissionsAsync()

    // console.log("getCameraPermissions", pp);
  }



  async function takePicture() {
    await cameraRef.current?.resumePreview()

    const picture = await cameraRef.current?.takePictureAsync({
      // base64: true,
    })

    console.log("[ImageCapture] takePicture", Object.keys(picture));
    

    if (picture) {
      // console.log(picture);,
      // setSelectedImage(picture);
      // setUploadPercentage(0);

      // const serverImage = await uploadImage(picture);
      const image :OrderImage = {
        uploaded: false,
        url: null,
        image: picture,
      }

      onPictureTaken?.(image)
    }
  }



  const fetchImageFromUri = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };


  // DEPRECATED
  // Idea, make a hook/utils?
  const uploadImage = async (pickerResult) => {
    const formData = new FormData();

    if (Platform.OS === "web") {
      const image = await fetchImageFromUri(pickerResult.uri)

      formData.append('file', image, 'image.jpg');
    } else {
      const localUri = Platform.OS === 'ios' ? pickerResult.uri.replace('file://', '') : pickerResult.uri
      const filename = localUri.split('/').pop();

      // Infer the type of the image
      const match = /\.(\w+)$/.exec(filename);
      const type = match ? `image/${match[1]}` : `image/jpeg`;

      formData.append('file', {
        name: "image.jpg",
        type: type,
        uri: localUri,
      });
    }

    formData.append('target', 'Player');

    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // console.log("uploadpercent", percentCompleted);

        setUploadPercentage(percentCompleted)
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 30000,
    }


    setUploading(true)

    return false

    return api.post("/images/upload", formData, config)
      .then((response) => {
        // console.log("Upload succeded", response.data);

        // setUploading(false)
        setUploadPercentage(0)
        setSelectedImage(null)

        //////
        // changePlayerImage(response.data)
        // setImageUrl(response.data.url)

        return response.data;
      })
      .catch((error) => {
        console.log("Upload error", error);
        setUploading(false)
      });
  }

  return (
    <Modal visible={true} onRequestClose={onRequestClose} transparent>
      <TouchableOpacity style={tw`absolute bg-black/50 top-0 right-0 left-0 bottom-0`} onPress={onRequestClose} />

      <View style={tw`absolute top-20 left-5 right-5 bottom-20 px-5 py-5 bg-white rounded-xl`}>
        {
          (Camera && !uploading) &&
            <Camera
              style={tw`w-full h-full`}
              type={CameraType.back}
              ref={cameraRef}
              // ratio={"1:1"}
              onCameraReady={async () => {
                // requestCameraPermissions()
              }}
              barCodeScannerSettings={{
                barCodeTypes: ["qr"],
              }}
              onBarCodeScanned={onBarCodeScanned}
            // pictureSize={"1500x1500"}
            >
              {
                ! onBarCodeScanned &&
                  <TouchableOpacity
                    style={tw`bg-white self-center absolute bottom-2 h-14 w-14 rounded-full items-center justify-center p-1`}
                    onPress={takePicture}
                  >
                    <View style={tw`rounded-full border-2 border-black w-full h-full`} />
                    {/* <Ionicons name='camera-outline' size={30} /> */}
                  </TouchableOpacity>
              }
            </Camera>
        }

        
      </View>

    </Modal>
  )
}
