import * as React from 'react';
import { Button, StyleSheet, TextInput, View } from 'react-native'

import api from "../../utils/api"
import tw from '../../styles/tailwind';
import Text from '../../components/Text';
import Icon from '../../components/Icon';


export default function ExamplesScreen({ navigation }) {

  return (
    <View style={tw`flex-1 items-center justify-center`}>
      <Text style={tw`mb-2`}>Ejemplos:</Text>


      <View style={tw`mb-4 bg-gray-200 px-4 py-4 rounded-lg`}>
        <Text><Icon name='user' size={14} color={tw.color('blue-400')} /> Esta pantalla puede ser encontrada en screens/Examples/ExamplesScreen.tsx</Text>
        <Text style={tw`font-600`}>Y esta esa una fuente custom!</Text>
      </View>


      {/**
       * 
       * EjemploFormulario
       * 
       */}
      <Button
        title={"Mis ordenes"}
        color={tw.color('primary')}
        onPress={() => {
          navigation.navigate('Orders.Index', { codeId: 'fasfe'})
        }}
      />

      <Button
        title={"Código prueba"}
        color={tw.color('primary')}
        onPress={() => {
          navigation.navigate('Codes.Detail', { codeId: 'fasfe'})
        }}
      />

    </View>
  );
}
