import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import Text from './../Text';
import Icons from '../../components/Icon';
import tw from '../../styles/tailwind';


interface ButtonProps{
  label: string,
  onPress: () => void,
  icon?: string|object,
  iconSize?: number,
  style?: any,
  rightItem?: any,
  disabled?: boolean,
}


export default function Button(props:ButtonProps) {
  return (
    <TouchableOpacity
      style={tw.style(`py-3 rounded-xl px-16 items-center justify-center bg-black`, { "opacity-60": props.disabled }, props.style?.container)}
      disabled={props.disabled}
      onPress={props.onPress}
    >

      {
        (props.icon && typeof props.icon == 'string') && <Icons name={`${props.icon}`} size={props.iconSize ?? 23} color={'#000'} style={props.style?.iconstyle}/>
      }

      {
        (props.icon && typeof props.icon == 'object') && props.icon
      }
      
      {/* Se cambió a semibold */}
      <Text style={tw.style(`text-white font-700 text-xl`, props.labelColor && `text-[${props.labelColor}]`, props.style?.label)}>{props.label}</Text> 

      {
        props.rightItem
      }
    </TouchableOpacity>
  )
}